.sidebar-right {
    display: flex;
    flex-direction: column-reverse;
    position: fixed;
    background: linear-gradient(150deg, rgba(255,255,255,0.7)0%, rgba(175,175,175,0.7)100%);
    height: 650px;
    z-index: 10;
    margin-top: auto;
    margin-bottom: auto;
    top: 0%;
    bottom: 0%;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 250px;
    font-size: 20px;
    right: 0px;
    box-shadow:  0px 1px 10px black;
}
.link {
    text-decoration: none;
}
.sidebar-right .button-sidebar-right{
    color: black;
    font-size: 17px;
    font-weight: 700;
    padding: 10px;
    align-items: center;
    border: none;
    outline: none;
    
    background: white;
    border-radius: 10px;
    position: relative;
    width: 100%;
    margin: 10px;
}

.sidebar-right .button-sidebar-right:hover{
    background-color: #e4e4e4;
    cursor: pointer;
}

.menu-title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
}