.landing {
    width: 100%;
}
.landing #map{
    width: 100%;
}

.dock-window {
    width: 100%;
    top: 100vh;
    position: absolute;
    z-index: 1000;
}