.sidebar-right-full {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    height: 100vh;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    width: 250px;
    font-size: 20px;
    padding: 13px;
    align-items: baseline;
    background: linear-gradient(270deg, rgba(255,255,255,1)-80%, rgba(175,175,175,1)150%);
    box-shadow:  0px 1px 10px black;
    right: 0%;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

input[type="file"] {
    display: none;
}

.sidebar-right-full .button-sidebar-right-full {
    position: relative;
    display: flex;
    justify-content: center;
    border: none;
    background: #ffffff;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    color: black;
    justify-content: center;
    align-items: center;
}

.sidebar-right-full .button-sidebar-right-full:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}
