.info-farm-regular {
    background-color: #fff;
    opacity: 1.0;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    width: 210px;
    color: black;   
}

.info-farm-regular:hover{
    background-color: #e4e4e4;
}

.info-farm-irregular {
    background-color: #ff9494;
    opacity: 1.0;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    width: 210px;
    color: black;   
}

.info-farm-irregular:hover{
    background-color: #f00;
}
.info-farm .name {
    color: #000;
    font-size: 17px;
    font-stretch: normal;
    padding: 3px;
    align-items: center;
    text-align: center;
    font-weight: 600;
    justify-content: center;
    text-indent: 10fr;
}
.info-farm .name .irregular {
    color: #b00;
}
.info-farm .label {
    margin-top: 10px;
    font-size: 15px;
    color: black;
    margin-bottom: 5px;
}

.info-farm .field {
    font-size: 14px;
    border: none;
    width: 100%;    
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #afafaf;
    border-radius: 5px;
}
.data {
    font-size: 14px;
    border: none;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-color: #afafaf;
    border-radius: 5px;
    width: 100%;
    /* color: #fff; */
}