.sidebar-left-full {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 10;
    height: 100vh;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 250px;
    font-size: 20px;
    padding: 13px;
    align-items: baseline;
    background: linear-gradient(270deg, rgba(255, 255, 255, 1)-80%, rgba(175, 175, 175, 1) 150%);
    box-shadow: 0px 1px 10px black;
    left: 0%;
}

.sidebar-left-full .inputs {
    position: relative;
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 10px;
    align-items: center;
    padding: 10px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    display: flexbox;
    top: 15px;
    line-height: 40px;
    font-weight: 500;
}

.sidebar-left-full .button-sidebar-left-full {
    position: relative;
    display: flex;
    justify-content: center;
    border: none;
    background: #ffffff;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    color: black;
    justify-content: center;
    align-items: center;
}

.sidebar-left-full .button-sidebar-left-full:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}

.sidebar-left-full .buttonCadastro {
    display: flex;
    justify-content: center;
    border: none;
    background: #d4d4d4;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding: 8px;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    color: black;
    justify-content: center;
    align-items: center;
}

.sidebar-left-full .buttonCadastro:hover {
    background-color: #fff;
    cursor: pointer;
}

.sidebar-left-full .field {
    background: rgba(175, 175, 175, 0.7);
    width: 100%;
    height: 30px;
    display: flex;
    outline-width: 0;
    color: black;
    border: 10px;
    border-radius: 10px;
    font-size: 17px;
    padding: 10px;
}

.sidebar-left-full .option {
    border: 0px;
    width: 100%;
    height: 30px;
    font-size: 17px;
    color: red;
    padding: 5px;
}

.sidebar-left-full .label {
    padding-top: 10px;
    padding-bottom: 0px;
    color: #000;
}