.login #map {
    z-index: -1;
}

.login .opacidade {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 0;
    position: fixed;
    font-size: 0;
}

.login .logo-emabrapa {
    color: #000;
}

.login .container {
    background: linear-gradient(150deg, #fff 0%, #afafaf 100%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 400px;
    height: 550px;
    position: fixed;
    top: 13%;
    margin-left: 60%;

    box-shadow: 0px 1px 10px black;
}

.login .container h4 {
    font-family: 'Roboto Slab';
    font-weight: 500;
    margin-top: 40px;
    font-size: 15px;
    color: #1A1A1D;
    background-color: transparent;
}

.login .loginInput {
    display: flex;
    align-items: center;
    color: #000;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 3px;

    width: 98%;
    height: 50px;
}

.login .loginInput svg {
    margin-left: 10px;
    font-size: 25px;
}

.login .loginInput input {
    background: transparent;
    width: 100%;
    outline-width: 0;
    color: #000;
    border: none;
    font-size: 17px;
    padding-left: 10px;
    padding-right: 10px;
}

.login .btn {
    width: 98%;
    background-color: #202024;
    color: #EFF2F5;
    font-weight: 800;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 2px;
    margin-bottom: 2px;
    border: none;
    text-align: center;
    padding: 7px;
    text-decoration: none;

    outline-width: 0;
}

.login .btn:hover {
    background-color: #121214;
    cursor: pointer;
}

.login .div-central {
    width: 98%;
    text-align: center;
    padding-bottom: 10px;
}

.login .embrapaLogo {
    /* height: 100px; */
    width: 150px;
}

.login .aibaLogo {
    width: 125px;
    height: 40px;
    /* padding-left: -10px; */
}

.login .adabLogo {
    height: 75px;
    padding-right: 10px;
}

.login .logos {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.login .form {
    width: 98%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login .btn-form {
    width: 100%;
    background-color: #202024;
    color: #EFF2F5;
    font-weight: 800;
    height: 40px;
    border-radius: 10px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 2px;
    border: none;
    text-align: center;
    padding: 7px;
    text-decoration: none;

    outline-width: 0;
}

.login .btn-form:hover {
    background-color: #121214;
    cursor: pointer;
}

.login .error {
    color: #F00;
    font-size: 10px;
    width: 100%;
    text-align: right;
    padding-right: 10px;
}

.login .btn-show {
    background: transparent;
    border: none;
    padding-right: 10px;
}

.login .btn-show:hover {
    cursor: pointer;
}