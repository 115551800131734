.sidebar-left {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    z-index: 10;
    position: fixed;
    background: linear-gradient(150deg, rgba(255, 255, 255, 0.7) 0%, rgba(175, 175, 175, 0.7) 100%);
    width: 250px;
    height: 650px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0%;
    bottom: 0%;
    left: 0px;
    align-items: center;
    text-decoration: none;
    box-shadow: 0px 1px 10px black;
    overflow-y: auto;
    overflow-x: hidden;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.sidebar-left .padding-15px {
    padding-left: 15px;
    padding-right: 15px;
}

.sidebar-left .logout {
    background: white;
    color: black;
    justify-content: center;
    width: 220px;
    align-items: baseline;
    display: flex;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
}

.sidebar-left .logout:hover {
    background-color: #e4e4e4;
    cursor: pointer;
}

.sidebar-left .padding-left-15px {
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 8px;
}