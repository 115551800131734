.titulo {
    position: fixed;
    right: 0;
    /* z-index: 1000; */
    left: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    top: 0px;
    font-size: 25px;
    width: 600px;
    /* border-radius: 10px; */
    font-style: normal;
    font-stretch: wider;
    font-weight: 700;
    /* background: linear-gradient(150deg, rgba(255, 255, 255, 0.7)0%, rgba(175, 175, 175, 0.7)100%); */
    padding-top: 20px;
    padding-bottom: 20px;
    /* box-shadow: 0px 1px 10px black; */
}