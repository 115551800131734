.landing {
    position: fixed;
}
#map {
    position: fixed;
    left: 0;
    right: 0;
    /* width: 67vw; */
    margin-left: auto;
    margin-right: auto;
    z-index: -1;
}